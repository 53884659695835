<template>
  <div class="container">
    <div class="contact-box">
      <div class="box-title">
        <div class="title">
          合作伙伴
        </div>
      </div>
      <div class="content-box">
        <div class="content">
          <img src="../assets/res/friends/wtlz.png" class="content-img" alt="">
        </div>
        <div class="content">
          <img src="../assets/res/friends/szaf.png" class="content-img" alt="">
        </div>
        <div class="content">
          <img src="../assets/res/friends/bjyd.jpg" class="content-img2" alt="">
        </div>
        <div class="content">
          <img src="../assets/res/friends/qhdx.jpg" class="content-img2" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  min-height: 600px;
  width: 1200px;
  margin: 0 auto;

  .box-title {
    width: 100%;
    border-bottom: 2px #999 solid;
    .title {
      margin-bottom: -2px;
      width: 80px;
      line-height: 32px;
      color: #333;
      font-size: 14px;
      font-weight: 400;
      text-shadow: none;
      border-bottom: 2px #ac0000 solid;
    }
  }

  .contact-box {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    .content {
      margin: 20px 0 0 10px;
          // width: 200px;
          // height: 30px;
      p {
        font-size: 14px;
        line-height: 1.8;
        padding: 0;
        margin: 0;
        white-space: normal;
        word-wrap: break-word;
        word-break: normal;
        overflow: hidden;
        width: 100%;
        text-align: left;
      }
      .content-img2 { 
          width: 560px;
          height: 178px;      
      }   
      .content-img { 
          width: 200px;
          height: 30px;      
      }
    }

  }

  .map-box {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    .content-box {
      margin: 20px 0 10px 0;
    }
  }
}
</style>
